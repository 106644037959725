import api from "@/api"
import workflowStore from "@/api/workflowStore"
import Duration from "@/components/Duration"
import workflowHelper from "@/helpers/workflowHelper"
import { Workflow, WorkflowStep, WorkflowTemplate } from "@/types"
import { useState, useEffect, useCallback, SyntheticEvent, useContext } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Timestamp from "./Timestamp"

import styles from "./WorkflowDetails.module.scss"
import StepList from "./StepList"
import Template from "./Template"
import WorkflowLogViewer from "@/components/logviewer/WorkflowLogViewer"
import Workspace from "@/components/workspace/Workspace"
import WorkflowStatus from "../monitor/WorkflowStatus"
import { Button } from "primereact/button"
import { FaTrashAlt } from "react-icons/fa"
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup"
import useToast from "@/hooks/useToast"
import ToastContext from "@/ToastContext"

interface WorkflowDetailsProps {}

function WorkflowDetails({}: WorkflowDetailsProps) {
  const [searchParams, _] = useSearchParams()
  const { workflowId } = useParams()
  const [workflow, setWorkflow] = useState<Workflow | null>(null)
  const [template, setTemplate] = useState<WorkflowTemplate | null>(null)
  const [activeTab, setActiveTab] = useState<string>("steps")
  const navigate = useNavigate()
  const { showSuccess } = useContext(ToastContext)

  const onUpdate = (workflow: Workflow) => {
    setWorkflow(workflow)
  }

  useEffect(() => {
    if (workflowId != null) {
      workflowStore.subscribeWorkflow(workflowId, onUpdate)
      return () => {
        workflowStore.unsubscribeWorkflow(workflowId, onUpdate)
      }
    }
  }, [workflowId])

  useEffect(() => {
    if (workflow != null) {
      const templateId = `${workflow.name}@${workflow.version}`
      api.protected.getWorkflowTemplate(templateId!).then(({ template }) => {
        setTemplate(template)
      })
    }
  }, [workflow?.id])

  const handleRerunFailedStep = useCallback(
    async (step: WorkflowStep) => {
      await api.protected.rerunFailedWorkflowStep(workflow!.id, step.id)
    },
    [workflow],
  )

  const handleResumeStaleWorkflow = useCallback(async () => {
    await api.protected.resumeStaleWorkflow(workflow!.id)
  }, [workflow])

  if (workflow === null || template === null) return null

  const statistics = workflowHelper.getWorkflowStatistics(workflow)

  const renderResumeButton = () => {
    if (!workflow.isStale) return null
    if (!workflow.events.find((event) => event.type === "workflow-started"))
      return "Workflow can't be resumed. You can manually start another instance of this workflow."
    return (
      <Button
        icon="pi pi-play"
        className={styles.resumeButton}
        severity="warning"
        label="Resume"
        onClick={handleResumeStaleWorkflow}
      />
    )
  }

  const handleBackClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (searchParams.get("from") === "search") {
      navigate(-1) //history back to keep search params
    } else {
      // from is 'monitor' or undefined
      navigate("/app/workflows/monitor")
    }
  }

  const handleDeleteWorkflow = async () => {
    await api.protected.deleteWorkflow(workflow.id)
    showSuccess({ msg: "Workflow deleted" })
    if (searchParams.get("from") === "search") {
      navigate("/app/workflows/list")
    } else {
      navigate("/app/workflows/monitor")
    }
  }

  const showDeleteWarningPopup = (event: SyntheticEvent) => {
    if (event.currentTarget instanceof HTMLElement) {
      confirmPopup({
        target: event.currentTarget,
        message: "Do you really want to delete this workflow?",
        icon: "pi pi-exclamation-triangle",
        accept: handleDeleteWorkflow,
        acceptClassName: "p-button-danger",
      })
    }
  }

  return (
    <div className={styles.container}>
      <header>
        <h2>
          {/* <Link to="/app/templates">Templates</Link> <i className="pi pi-chevron-right" /> {templateId} */}
          <a href="#" onClick={handleBackClick}>
            {searchParams.get("from") === "search" ? "Search" : "Monitor"}
          </a>
          <i className="pi pi-chevron-right" />
          Workflow Details
        </h2>
      </header>
      <section className={styles.attributes}>
        <div className={styles.attribute}>
          <div className={styles.label}>Name</div>
          <div className={styles.value}>{template.displayName}</div>
        </div>
        <div className={styles.attribute}>
          <div className={styles.label}>Version</div>
          <div className={styles.value}>{workflow.version}</div>
        </div>
        <div className={styles.attribute}>
          <div className={styles.label}>ID</div>
          <div className={styles.value}>{workflow.id}</div>
        </div>
        <div className={styles.attribute}>
          <div className={styles.label}>Overall Status</div>
          <div>
            <WorkflowStatus status={workflowHelper.getStatus(workflow)} />
          </div>
        </div>
        <div className={styles.attribute}>
          <div className={styles.label}>Started</div>
          <div className={styles.value}>
            <Timestamp timestamp={workflow.startedAt} />
          </div>
        </div>
        <div className={styles.attribute}>
          <div className={styles.label}>Completed</div>
          <div className={styles.value}>
            <Timestamp timestamp={statistics.completedAt} />
          </div>
        </div>
        <div className={styles.attribute}>
          <div className={styles.label}>Duration</div>
          <div className={styles.value}>{statistics.duration ? <Duration duration={statistics.duration} /> : "-"}</div>
        </div>
        <div className={styles.attribute}>{renderResumeButton()}</div>
        <Button className={styles.deleteBtn} size="small" text icon="pi pi-trash" onClick={showDeleteWarningPopup} />
        <ConfirmPopup />
      </section>

      <ul className={styles.tabs}>
        <li
          className={activeTab === "steps" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("steps")
          }}
        >
          Steps
        </li>
        <li
          className={activeTab === "log" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("log")
          }}
        >
          Log
        </li>
        <li
          className={activeTab === "workspace" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("workspace")
          }}
        >
          Workspace
        </li>
        <li
          className={activeTab === "template" ? styles.activeTab : styles.tab}
          onClick={() => {
            setActiveTab("template")
          }}
        >
          Template
        </li>
      </ul>
      <div className={styles.tabDisplay}>
        {activeTab === "steps" && <StepList workflow={workflow} onRerunFailedStep={handleRerunFailedStep} />}
        {activeTab === "log" && <WorkflowLogViewer workflow={workflow} template={template} />}
        {activeTab === "workspace" && <Workspace workflowId={workflow.id} />}
        {activeTab === "template" && <Template template={template} />}
      </div>
    </div>
  )
}
export default WorkflowDetails
