import { Card } from "primereact/card"
import { Link } from "react-router-dom"

const LogoutConfirmation = () => {
  return (
    <Card title="You have been logged out">
      Do you want to use Nebula again? Please <Link to="/">log in</Link> again.
    </Card>
  )
}

export default LogoutConfirmation
