import { Card } from "primereact/card"
import { ProgressSpinner } from "primereact/progressspinner"

const LoginSpinner = () => {
  return (
    <Card title="Authenticating...">
      <ProgressSpinner />
    </Card>
  )
}

export default LoginSpinner
