import websocket from "@/websocket"
import api from "@/api/protected"

import { Workflow } from "@/types"

export interface WorkflowMessageHandler {
  (workflow: Workflow, isDelete: boolean): void
}

export interface WorkflowIdMessageHandler {
  (workflowIds: string[]): void
}

const store: Map<string, Workflow> = new Map()
let idList: string[] = []

const workflowSubscriptions: Map<string, Set<WorkflowMessageHandler>> = new Map()
const listSubscriptions: Set<WorkflowMessageHandler> = new Set()
const idListSubscriptions: Set<WorkflowIdMessageHandler> = new Set()

const messageHandler = (message: any) => {
  let updatedWorkflow: Workflow | null = null
  let isDelete = false
  let updateIdList = false

  if (message.type === "workflow-update") {
    store.set(message.workflow.id, message.workflow)
    updatedWorkflow = message.workflow

    if (!idList.includes(message.workflow.id)) {
      idList = [message.workflow.id, ...idList]
      updateIdList = true
    }
  } else if (message.type === "step-update") {
    const { step } = message
    if (step != null) {
      const workflow = store.get(step.workflowId)

      if (workflow != null) {
        // sometimes we get a step update for a workflow that we have not received yet through "workflow-update"

        const newSteps = [...workflow.steps]

        const stepIdx = newSteps.findIndex((s) => s.id === step.id)
        if (stepIdx === -1) {
          newSteps.push(step)
        } else {
          newSteps[stepIdx] = step
        }

        const newWorkflow = { ...workflow, steps: newSteps }

        store.set(newWorkflow.id, newWorkflow)
        updatedWorkflow = newWorkflow
      } else {
        console.error("step update for unknown workflow", step)
      }
    }
  } else if (message.type === "workflow-delete") {
    if (message.workflowId != null) {
      updatedWorkflow = store.get(message.workflowId) as Workflow
      isDelete = true
      store.delete(message.workflowId)

      if (idList.includes(message.workflowId)) {
        idList = [...idList].filter((id) => id !== message.workflowId)
        updateIdList = true
      }
    }
  } else if (message.type === "workflow-remove-from-monitor") {
    if (message.workflowId != null) {
      updatedWorkflow = store.get(message.workflowId) as Workflow
      isDelete = true
      store.delete(message.workflowId)

      if (idList.includes(message.workflowId)) {
        idList = [...idList].filter((id) => id !== message.workflowId)
        updateIdList = true
      }
    }
  }

  if (updatedWorkflow != null) {
    const workflowId = updatedWorkflow.id
    if (workflowSubscriptions.has(workflowId)) {
      const handlers = workflowSubscriptions.get(workflowId)
      if (handlers != null) {
        for (const handler of handlers) {
          handler(updatedWorkflow, isDelete)
        }
      }
    }

    for (const handler of listSubscriptions) {
      handler(updatedWorkflow, isDelete)
    }
  }

  if (updateIdList) {
    for (const handler of idListSubscriptions) {
      handler(idList)
    }
  }
}

const init = async () => {
  const workflows = await api.loadMonitor()
  idList = []
  if (workflows?.length > 0) {
    workflows.forEach((wf) => {
      store.set(wf.id, wf)
      idList.push(wf.id)
    })
  }

  websocket.registerMessageHandler(messageHandler)
}

const subscribeWorkflow = async (workflowId: string, handler: WorkflowMessageHandler) => {
  const workflow = store.get(workflowId)
  if (workflow) {
    if (!workflowSubscriptions.has(workflowId)) {
      workflowSubscriptions.set(workflowId, new Set())
    }
    workflowSubscriptions.get(workflowId)?.add(handler)
    handler(workflow, false)
  } else {
    api.getWorkflowDetails(workflowId).then((workflow) => {
      handler(workflow, false)
    })
  }
}

const unsubscribeWorkflow = (workflowId: string, handler: WorkflowMessageHandler) => {
  if (workflowSubscriptions.has(workflowId)) {
    const handlers = workflowSubscriptions.get(workflowId)
    if (handlers != null) {
      handlers.delete(handler)
    }
  }
}

const subscribeList = (handler: WorkflowMessageHandler) => {
  listSubscriptions.add(handler)

  for (const workflow of store.values()) {
    handler(workflow, false)
  }
}

const unsubscribeList = (handler: WorkflowMessageHandler) => {
  if (!listSubscriptions.has(handler)) console.warn("handler not found in listSubscriptions")
  listSubscriptions.delete(handler)
}

const subscribeIdList = (handler: WorkflowIdMessageHandler) => {
  idListSubscriptions.add(handler)
  handler(idList)
}

const unsubscribeIdList = (handler: WorkflowIdMessageHandler) => {
  if (!idListSubscriptions.has(handler)) console.warn("handler not found in idListSubscriptions")
  idListSubscriptions.delete(handler)
}

const getWorkflow = (workflowId: string): Workflow | undefined => {
  return store.get(workflowId)
}

const getWorkflows = (): Workflow[] => {
  return Array.from(store.values())
}

export default {
  init,
  subscribeWorkflow,
  unsubscribeWorkflow,
  subscribeList,
  unsubscribeList,
  subscribeIdList,
  unsubscribeIdList,
  getWorkflow,
  getWorkflows,
}
