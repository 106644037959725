import api from "@/api"
import { Workflow, WorkflowTemplate } from "@/types"
import { useEffect, useState } from "react"
import logParser from "./logParser"
import LogTable from "./LogTable"
import { formatDate } from "@/helpers/dateHelpers"
import ErrorBoundary from "../ErrorBoundary"

interface WorkflowLogViewerProps {
  workflow: Workflow
  template: WorkflowTemplate
}

function WorkflowLogViewer({ workflow, template }: WorkflowLogViewerProps) {
  const [logContent, setLogContent] = useState<string | null>(null)

  useEffect(() => {
    api.protected.getWorkflowLog(workflow.id).then((content) => {
      setLogContent(content)
    })
  }, [])

  if (workflow == null || logContent == null || template == null) return null

  let parsedLog: Array<Record<string, string>> = []

  if (logContent.length > 0) {
    parsedLog = logParser.parse(logContent, workflow as Workflow)
  }

  const showTable = parsedLog.length > 0

  const name = workflow?.name
  const version = workflow?.version
  const date = formatDate(workflow?.events?.[0]?.timestamp)

  return (
    // @ts-ignore
    <ErrorBoundary fallback={<div>Error in WorkflowLogViewer component</div>}>
      <div>{showTable && <LogTable log={parsedLog} workflow={workflow} template={template} />}</div>
    </ErrorBoundary>
  )
}
export default WorkflowLogViewer
