import api from "@/api"
import { Workflow, WorkflowListEntry } from "@/types"
import { useEffect, useState } from "react"

import WorkflowStatus from "../monitor/WorkflowStatus"
import Timestamp from "../details/Timestamp"
import { useNavigate } from "react-router-dom"

interface Props {
  workflowId: string
}

function WorkflowListRow({ workflowId }: Props) {
  const navigate = useNavigate()
  const [workflow, setWorkflow] = useState<WorkflowListEntry | null>(null)

  useEffect(() => {
    api.protected.getWorkflowListEntry(workflowId).then((workflow) => setWorkflow(workflow))
  }, [workflowId])

  if (workflow == null) return null

  const showDetails = (id: string) => {
    navigate(`/app/workflows/${id}?from=search`)
  }

  return (
    <tr key={workflow.id} onClick={(e) => showDetails(workflow.id)}>
      <td>
        <WorkflowStatus status={workflow.state} />
      </td>
      <td>{workflow.id}</td>
      <td>{workflow.displayName}</td>
      <td>{workflow.version}</td>
      <td>
        <Timestamp timestamp={workflow.startedAt} />
      </td>
    </tr>
  )
}
export default WorkflowListRow
