import AppRoutes from "@/AppRoutes"
import { useCallback, useReducer, useRef } from "react"
import AppStateContext from "./AppStateContext"
import appStateReducer from "./appStateReducer"
import { Toast } from "primereact/toast"
import ToastContext from "./ToastContext"
import useToast from "./hooks/useToast"
import xhr from "./api/xhr"

const App = () => {
  const [appState, dispatch] = useReducer(appStateReducer, { workflowTemplates: [] })
  const appStateDispatch = useCallback(dispatch, [])
  const toastRef = useRef<Toast>(null)

  const toast = useToast(toastRef)

  xhr.setToast(toast)

  const { showError, showWarning, showSuccess, showInfo } = toast

  return (
    <AppStateContext.Provider value={{ appState, dispatch: appStateDispatch }}>
      <ToastContext.Provider value={{ showError, showWarning, showSuccess, showInfo }}>
        <Toast ref={toastRef} />
        <AppRoutes />
      </ToastContext.Provider>
    </AppStateContext.Provider>
  )
}

export default App
