import { Workflow } from "@/types"
import { useEffect, useState } from "react"
import Timestamp from "../details/Timestamp"
import WorkflowProgress from "./WorkflowProgress"
import WorkflowStatus from "./WorkflowStatus"

import styles from "./WorkflowMonitorList.module.scss"
import { useNavigate } from "react-router-dom"
import workflowStore from "@/api/workflowStore"
import workflowHelper from "@/helpers/workflowHelper"

interface WorkflowMonitorRowProps {
  workflowId: string
}

function WorkflowMonitorRow({ workflowId }: WorkflowMonitorRowProps) {
  const [workflow, setWorkflow] = useState<Workflow | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    workflowStore.subscribeWorkflow(workflowId, handleWorkflowUpdate)
    return () => {
      workflowStore.unsubscribeWorkflow(workflowId, handleWorkflowUpdate)
    }
  }, [workflowId])

  const handleWorkflowUpdate = (workflow: Workflow, isDelete: boolean) => {
    if (workflow.id === workflowId) {
      if (isDelete) {
        setWorkflow(null)
      } else {
        setWorkflow(workflow)
      }
    }
  }

  const showDetails = (id: string) => {
    navigate(`/app/workflows/${id}`)
  }

  if (!workflow) return null

  const startedAt = workflow.startedAt

  return (
    <tr
      key={workflow.id}
      className={styles.row}
      onClick={(e) => {
        showDetails(workflow.id)
      }}
    >
      <td>
        <WorkflowStatus status={workflowHelper.getStatus(workflow)} />
      </td>

      <td>{workflow.id}</td>
      <td>{workflow.displayName || `${workflow.name}`}</td>
      <td>{workflow.version}</td>
      <td>
        <WorkflowProgress workflow={workflow} />
      </td>
      <td>
        <Timestamp timestamp={startedAt} />
      </td>
    </tr>
  )
}
export default WorkflowMonitorRow
