import {
  FaUpload,
  FaDownload,
  FaTrashAlt,
  FaList,
  FaFolder,
  FaLink,
  FaUnlink,
  FaCopy,
  FaPlug,
  FaRegWindowClose,
} from "react-icons/fa"

import styles from "./FileOperation.module.css"

interface FileOperationProps {
  operation: string
}

function mapOperationAbbr(operation: string) {
  if (operation.startsWith("ftp")) return "FTP"
  if (operation.startsWith("sftp")) return "SFTP"
  if (operation.startsWith("s3")) return "S3"
  if (operation.startsWith("file")) return "File"
  if (operation.startsWith("gcs")) return "GCS"
  if (operation.startsWith("gdr")) return "GDR"
  return "operation"
}

function mapOperationIcon(operation: string) {
  if (operation.endsWith("upload-file")) return <FaUpload />
  if (operation.endsWith("upload-list")) return <FaUpload />
  if (operation.endsWith("download-file")) return <FaDownload />
  if (operation.endsWith("download-list")) return <FaDownload />
  if (operation.endsWith("delete")) return <FaTrashAlt />
  if (operation.endsWith("list")) return <FaList />
  if (operation.endsWith("ensure-dir")) return <FaFolder />
  if (operation.endsWith("delete-file")) return <FaTrashAlt />
  if (operation.endsWith("connection-open")) return <FaPlug />
  if (operation.endsWith("connection-close")) return <FaRegWindowClose />
  if (operation.endsWith("unlink")) return <FaUnlink />
  if (operation.endsWith("link")) return <FaLink />
  if (operation.endsWith("copy")) return <FaCopy />
}

function FileOperation({ operation }: FileOperationProps) {
  if (!operation) return null

  const operationAbbr = mapOperationAbbr(operation)
  const icon = mapOperationIcon(operation)

  const iconComponent = icon != null ? <div className={styles.icon}>{icon}</div> : null

  return (
    <div className={styles.operation}>
      {iconComponent}
      <div title={operation}>{operationAbbr}</div>
    </div>
  )
}
export default FileOperation
