import React, { useContext, useEffect, useState } from "react"
import api from "@/api"
import { Link } from "react-router-dom"
import { WorkflowExecution, WorkflowTemplate } from "@/types"
import styles from "./DisplayTemplateWorkflowList.module.scss"
import { TabView, TabPanel } from "primereact/tabview"
import DisplayMetaData from "./DisplayMetaData"
import ToggleButton from "./ToggleButton"
import ToastContext from "@/ToastContext"
import { Divider } from "primereact/divider"
import { Button } from "primereact/button"
import { Message } from "primereact/message"

const capitalizeFirstLetter = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

interface DisplayNameListProps {
  groupedTemplates: Record<string, WorkflowTemplate[]>
  workflowExecutions: Record<string, WorkflowExecution>
  groupFilter: string
}

const DisplayWorkflowTemplateList: React.FC<DisplayNameListProps> = ({
  groupedTemplates,
  workflowExecutions,
  groupFilter,
}) => {
  // Initialize activeIndex from local storage or default to 0
  const [activeIndex, setActiveIndex] = useState(() => {
    return JSON.parse(localStorage.getItem("activeTabIndex") || "null")
  })
  const [templatesState, setTemplatesState] = useState<Record<string, WorkflowTemplate[]>>({})
  const [group, setGroup] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const { showError, showSuccess } = useContext(ToastContext)
  // Update local storage whenever activeIndex changes
  useEffect(() => {
    localStorage.setItem("activeTabIndex", JSON.stringify(activeIndex))
    if (groupFilter === "none") {
      setGroup(false)
    } else {
      setGroup(true)
    }
  }, [activeIndex, groupFilter])

  useEffect(() => {
    // Deep clone groupedTemplates to avoid mutating props
    setTemplatesState(JSON.parse(JSON.stringify(groupedTemplates)))
  }, [groupedTemplates])

  const changeTab = (e: any) => {
    setActiveIndex(e.index) // Update active tab index on change
  }

  const handleToggle = (groupKey: string, templateIndex: number, newValue: boolean) => {
    const updatedTemplate = {
      ...templatesState[groupKey][templateIndex],
      isEnabled: newValue,
    }
    setTemplatesState((prevState) => {
      const updatedState = { ...prevState }
      const updatedTemplates = [...updatedState[groupKey]]
      updatedTemplates[templateIndex] = updatedTemplate
      updatedState[groupKey] = updatedTemplates
      return updatedState
    })

    saveTemplate(updatedTemplate)
  }
  const handleRefresh = () => {
    window.location.reload()
    setRefresh(!refresh)
  }

  const saveTemplate = async (template: WorkflowTemplate) => {
    const updatedTemplate = { ...template }
    delete updatedTemplate.version
    delete updatedTemplate.lastEditedBy

    let saved
    try {
      if (template.name && template.version) {
        saved = await api.protected.updateWorkflowTemplate(template.name, template.version, updatedTemplate)
      } else {
        showError({ msg: "Template name or version is missing" })
      }
      showSuccess({ msg: "Template has been updated" })

      if (!refresh) {
        setRefresh(true)
      }
    } catch (e: any) {
      console.log("e", e)
      showError({ msg: e.message })
    }
  }

  if (Object.keys(templatesState).length === 0) {
    return <div className={styles.warn}>There are no templates that are matching the filter settings.</div>
  }
  const renderTemplateList = (groupKey: string, templates: WorkflowTemplate[], workflowExecutions: Record<string, WorkflowExecution>) => {
    return (
      <div key={groupKey}>
        <h2 style={{ fontSize: "1.2rem" }}>{capitalizeFirstLetter(groupKey)}</h2>
        <ul className={styles.unOrderedList2} key={groupKey}>
          {templates.map((item: WorkflowTemplate, index: number) => {
            const execData = workflowExecutions[item.name];
            return (
              <li key={index} className={styles.list}>
                <div className={styles.card}>
                  <Link to={`/app/templates/${item.name}`}>{item.displayName}</Link>
                  <ToggleButton
                    value={item.isEnabled ?? false}
                    onChange={(newValue) => handleToggle(groupKey, index, newValue ?? false)}
                  />
                </div>
                <DisplayMetaData execData={execData} />
              </li>
            );
          })}
          <Divider />
        </ul>
      </div>
    );
  };
  
  const renderRefreshSection = () => {
    return (
      <>
        <Button
          label="Refresh"
          icon="pi pi-refresh"
          onClick={handleRefresh}
          severity="secondary"
          className={styles.refreshButton}
        />
        <Message
          style={{ marginLeft: "10px" }}
          severity="info"
          text="To see the changes, the page needs to be refreshed."
        />
      </>
    );
  };
  
  return (
    <>
      {group ? (
        <div className={styles.topLevelContainer}>
          <div>
            {Object.keys(templatesState).map((groupKey) =>
              renderTemplateList(groupKey, templatesState[groupKey], workflowExecutions)
            )}
          </div>
          <div>{refresh && renderRefreshSection()}</div>
        </div>
      ) : (
        <TabView activeIndex={activeIndex} onTabChange={changeTab} scrollable>
          {Object.keys(templatesState).map((groupKey) => (
            <TabPanel header={capitalizeFirstLetter(groupKey)} key={groupKey}>
              <div className={styles.contentContainer}>
                {renderTemplateList(groupKey, templatesState[groupKey], workflowExecutions)}
                {refresh && renderRefreshSection()}
              </div>
            </TabPanel>
          ))}
        </TabView>
      )}
    </>
  );
}

export default DisplayWorkflowTemplateList
