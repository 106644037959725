export const getOktaEnv = () => {
  const hostname = window.location.hostname

  if (hostname === "nebula.tamedia.ch") return "production"
  if (hostname === "nebula-staging.tamedia.ch") return "staging"
  if (hostname === "nebula-dev.tamedia.ch") return "localdev"
  return "localdev"
}

export const getEnv = () => {
  const hostname = window.location.hostname

  if (hostname === "nebula.tamedia.ch") return "production"
  if (hostname === "nebula-staging.tamedia.ch") return "staging"
  if (hostname === "nebula-dev.tamedia.ch") return "development"
  return "development"
}
