import ApiError from "@/ApiError"
import { getAuthToken } from "@/okta/OktaHelper"
import { Toast } from "@/types"

let toast: Toast

const setToast = (t: Toast) => {
  toast = t
}

const doFetch = async (method: string, url: string, data?: any) => {
  const isFileUpload = !!data && data.constructor.name === "FormData"

  const options: any = {
    method,
    headers: {
      Accept: "application/json",
    },
  }

  if (isFileUpload) {
    options.body = data
  } else {
    options.headers["Content-Type"] = "application/json"
    if (data) options.body = JSON.stringify(data)
  }

  const authToken = getAuthToken()
  if (authToken) {
    options.headers["Authorization"] = `Bearer ${authToken}`
  }

  let response
  let error: any
  let jsonData
  let text
  try {
    response = await fetch(url, options)
    text = await response.text()
    jsonData = JSON.parse(text)
  } catch (e: any) {
    if (e.name === "SyntaxError") {
      error = text
    } else {
      error = e
    }
  }

  if (!response || !response.ok || error) {
    const statusCode = response?.status

    if (statusCode === 401) {
      window.location.assign("/")
    } else {
      const message = jsonData
        ? jsonData.message || jsonData.error || jsonData.toString()
        : error?.toString() || "Unknown error"

      if (statusCode && (statusCode >= 500 || statusCode === 400)) {
        toast.showError({ msg: `API error: ${message}` })
      }

      throw new ApiError(message, statusCode)
    }
  }

  return jsonData
}

export default {
  setToast,
  doFetch,
  get: (url: string) => doFetch("get", url),
  put: (url: string, data = {}) => doFetch("put", url, data),
  post: (url: string, data = {}) => doFetch("post", url, data),
  delete: (url: string, data = {}) => doFetch("delete", url, data),
}
