export type Environment = "development" | "staging" | "production"

export interface Config {
  env: Environment
  [key: string]: any
}

const environment: Environment = (process.env.NODE_ENV as Environment) || "development"
import defaultConfig from "./default"
import devConfig from "./development"
import stagingConfig from "./staging"
import productionConfig from "./production"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let envConfig: any
switch (environment) {
  case "development":
    envConfig = devConfig
    break
  case "staging":
    envConfig = stagingConfig
    break
  case "production":
    envConfig = productionConfig
  default:
    break
}

const config: Config = { ...defaultConfig, ...envConfig, env: environment }

export default config

