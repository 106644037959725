interface TimestampProps {
  timestamp: Date | string | undefined | null
  includeDayName?: boolean
}

const formatter = new Intl.DateTimeFormat("de-CH", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
})

function Timestamp({ timestamp, includeDayName = false }: TimestampProps) {
  const getDayName = () => {
    if (timestamp) {
      var date = new Date(timestamp)
      return date.toLocaleDateString("en-US", { weekday: "long" })
    }
  }

  if (timestamp) {
    let formatted = ""

    if (typeof timestamp === "string") {
      formatted = formatter.format(new Date(timestamp))
    } else {
      formatted = formatter.format(timestamp)
    }

    return (
      <div style={{ whiteSpace: "nowrap" }}>
        {includeDayName ? `${getDayName()}, ` : ""}
        {formatted}
      </div>
    )
  } else {
    return <div>-</div>
  }
}
export default Timestamp
