import { Workflow } from "@/types"

const fileOpTexts: Record<string, string> = {
  "ftp-download-file": "Download from FTP",
  "ftp-download-list": "Download list from FTP",
  "ftp-upload-file": "Upload to FTP",
  "ftp-upload-list": "Upload list to FTP",
  "ftp-list": "Get list from FTP",
  "ftp-ensure-dir": "Ensure directory on FTP",
  "ftp-delete-file": "Delete from FTP",
  "ftp-delete-list": "Delete list from FTP",
  "ftp-connection-open": "Open FTP connection",
  "ftp-connection-close": "Close FTP connection",
  "sftp-download-file": "Download from SFTP",
  "sftp-download-list": "Download list from SFTP",
  "sftp-download-dir": "Download directory from SFTP",
  "sftp-upload-file": "Upload to SFTP",
  "sftp-upload-list": "Upload list to SFTP",
  "sftp-list": "Get list from SFTP",
  "sftp-ensure-dir": "Ensure directory on SFTP",
  "sftp-delete-file": "Delete from SFTP",
  "sftp-delete-list": "Delete list from SFTP",
  "sftp-delete-dir": "Delete Directory from SFTP",
  "sftp-connection-open": "Open SFTP connection",
  "sftp-connection-close": "Close SFTP connection",
  "s3-download-file": "Download from S3",
  "s3-download-list": "Download list from S3",
  "s3-download-dir": "Download directory from S3",
  "s3-upload-file": "Upload to S3",
  "s3-upload-list": "Upload list to S3",
  "s3-list": "Get list from S3",
  "s3-ensure-dir": "Ensure directory on S3",
  "s3-delete-file": "Delete from S3",
  "s3-delete-list": "Delete list from S3",
  "s3-delete-dir": "Delete directory from S3",
  "s3-connection-open": "Open S3 connection",
  "s3-connection-close": "Close S3 connection",
  "gcs-download-file": "Download from GCS",
  "gcs-download-list": "Download list from GCS",
  "gcs-upload-file": "Upload to GCS",
  "gcs-upload-list": "Upload list to GCS",
  "gcs-list": "Get list from GCS",
  "gcs-ensure-dir": "Ensure directory on GCS",
  "gcs-delete-file": "Delete from GCS",
  "gcs-delete-list": "Delete list from GCS",
  "gcs-connection-open": "Open GCS connection",
  "gcs-connection-close": "Close GCS connection",
  "gdr-download-file": "Download from GDR",
  "gdr-download-list": "Download list from GDR",
  "gdr-upload-file": "Upload to GDR",
  "gdr-upload-list": "Upload list to GDR",
  "gdr-list": "Get list from GDR",
  "gdr-ensure-dir": "Ensure directory on GDR",
  "gdr-delete-file": "Delete from GDR",
  "gdr-delete-list": "Delete list from GDR",
  "gdr-connection-open": "Open GDR connection",
  "gdr-connection-close": "Close GDR connection",
  "file-link": "Copy the file",
  "file-unlink": "Delete the file",
  "file-read": "Reading content of file",
  "file-write": "Writing file",
}

function parse(rawContent: string, workflow: Workflow) {
  const parsedLog: Array<Record<string, string>> = []
  let sortedLog: Array<Record<string, string>> = []
  try {
    if (rawContent != null) {
      const stepNames = buildStepNameMap(workflow)

      const lines = rawContent.split("\n")

      lines.forEach((line) => {
        if (line !== "") {
          const parsedLine = JSON.parse(line)

          if (parsedLine.op) {
            parsedLine.msg = buildFileOperationMessage(parsedLine)
          }

          if (parsedLine.stepId) {
            parsedLine.stepName = stepNames.get(parsedLine.stepId)
          }

          parsedLog.push(parsedLine)
        }
      })
    }

    sortedLog = parsedLog.sort((a: Record<string, string>, b: Record<string, string>) => {
      return a.time < b.time ? -1 : a.time > b.time ? 1 : 0
    })
  } catch (e) {
    console.error(e)
  }

  return sortedLog
}

function buildStepNameMap(workflow: Workflow) {
  const stepNameMap = new Map<string, string>()

  workflow?.steps?.forEach((step) => {
    stepNameMap.set(step.id, step.name)
  })

  return stepNameMap
}

function buildFileOperationMessage(parsedLine: Record<string, string>) {
  const { msg, op, sourceUrl, targetUrl } = parsedLine

  const text = fileOpTexts[op]

  const files = [parsedLine.sourceUrl, parsedLine.targetUrl].filter((item) => item != null).join(" -> ")

  return `${text} ${files}`
}

export default { parse }
