import React from "react"
import { WorkflowTemplate } from "./types"

export interface AppStateContextProperties {
  appState?: AppState
  dispatch?: (action: AppStateAction) => void
}

export type AppState = {}

export type AppStateAction = { type: "workflowTemplatesLoaded"; templates: WorkflowTemplate[] }

const contextProps: AppStateContextProperties = { appState: undefined, dispatch: undefined }
const AppStateContext = React.createContext(contextProps)

export default AppStateContext
