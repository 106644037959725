import { Workflow, WorkflowState } from "@/types"
import cx from "classnames"

import styles from "./WorkflowStatus.module.scss"

interface Props {
  status: WorkflowState
}

function WorkflowStatus({ status }: Props) {
  const statusClass = cx(
    styles.status,
    status === "requested" && styles.requested,
    status === "completed" && styles.completed,
    status === "failed" && styles.failed,
    status === "running" && styles.running,
    status === "stale" && styles.stale,
    status === "skipped" && styles.skipped,
    status === "listing" && styles.listing,
  )

  return <div className={statusClass}>{status}</div>
}
export default WorkflowStatus
