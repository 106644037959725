import prettyMilliseconds from "pretty-ms"

interface DurationProps {
  duration: number | undefined | null
}

function Duration({ duration }: DurationProps) {
  if (!duration) return null
  return <div>{prettyMilliseconds(duration)}</div>
}
export default Duration
