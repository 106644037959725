import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"

import "./index.scss"

import "primereact/resources/themes/saga-blue/theme.css" //theme
import "primereact/resources/primereact.min.css" //core css
import "primeicons/primeicons.css" //icons
import 'primeflex/primeflex.css'; //flex layout

import OktaSecurity from "./okta/OktaSecurity"

async function init() {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <BrowserRouter>
        <OktaSecurity>
          <App />
        </OktaSecurity>
      </BrowserRouter>
    </React.StrictMode>
  )
}

init()
