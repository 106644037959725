import { BreadCrumb } from "primereact/breadcrumb"
import { useNavigate } from "react-router-dom"

interface BreadcrumbsProps {
  workflowId: string
  path: string
  onPathChange: Function
}

function Breadcrumbs({ workflowId, path, onPathChange }: BreadcrumbsProps) {
  const dirs = path !== "/" ? path.split("/") : []

  let currentPath = ""

  const home = {
    icon: "pi pi-home",
    command: () => onPathChange("/"),
  }

  const items = dirs
    .map((dir) => {
      const itemPath = currentPath + "/" + dir
      currentPath += "/" + dir
      return { label: dir, command: () => onPathChange(itemPath) }
    })
    .filter((itemPath) => itemPath.label !== "")

  return (
    <div>
      <BreadCrumb home={home} model={items} />
    </div>
  )
}
export default Breadcrumbs
