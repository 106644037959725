import { Link } from "react-router-dom"
import logo from "@/assets/tamedia_logo.png"

import styles from "./Logo.module.scss"

const Logo = () => {
  return (
    <div className={styles.logo}>
      <Link to="/">
        <img src={logo} />
        Nebula
      </Link>
    </div>
  )
}

export default Logo
