import styles from "../../AppLayout.module.scss"
import cx from 'classnames'
import Logo from "./Logo"
import { NavLink } from "react-router-dom"
import { useOktaAuth } from "@okta/okta-react"
import { UserClaims, CustomUserClaims } from "@okta/okta-auth-js"
import { Button } from "primereact/button"
import { useEffect, useState } from "react"
import { getEnv } from "@/helpers/envHelper"
import websocket from "@/websocket"

const MainNavigation = () => {
  const { authState } = useOktaAuth()
  if (!authState?.isAuthenticated) return null
  return (
    <ul className={styles.mainNavigation}>
      <li>
        <NavLink to="/app/workflows/monitor">Monitor</NavLink>
      </li>
      <li>
        <NavLink to="/app/workflows/list">Workflows</NavLink>
      </li>
      <li>
        <NavLink to="/app/templates">Templates</NavLink>
      </li>
    </ul>
  )
}

const MetaNavigation = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [user, setUser] = useState<UserClaims<CustomUserClaims>>()

  useEffect(() => {
    const getUser = async () => {
      const u = await oktaAuth.getUser()
      setUser(u)
    }
    if (authState?.isAuthenticated) getUser()
  }, [!!authState?.isAuthenticated])

  if (!authState?.isAuthenticated) return null

  const logout = async () => {
    websocket.close()
    await oktaAuth?.signOut()
  }

  const renderUser = () => {
    if (!user) return null

    return (
      <div className={styles.user}>
        <i className="pi pi-user" /> {[user.given_name, user.family_name].filter((p) => !!p).join(" ")}
      </div>
    )
  }

  return (
    <div className={styles.metaNavigation}>
      <span>{renderUser()}</span>
      <Button severity="secondary" label="Log out" icon="pi pi-sign-out" onClick={logout} size="small" />
    </div>
  )
}

const Header = () => {
  let environment = getEnv()
  const classNames = cx(styles.header, styles[environment])

  return (
    <div className={classNames}>
      <div style={{ display: "flex" }}>
        <Logo />
        <MainNavigation />
      </div>
      <MetaNavigation />
    </div>
  )
}

export default Header
