import { WorkflowStep } from "@/types"
import classNames from "classnames/bind"

import styles from "./StepStatus.module.scss"
const cx = classNames.bind(styles)

interface WorkflowStatusProps {
  step: WorkflowStep
}

function StepStatus({ step }: WorkflowStatusProps) {
  const status = step.state
  const statusClass = cx({
    status: true,
    completed: status === "completed",
    failed: status === "failed",
    running: status === "running",
    queued: status === "queued",
    waiting: status === "waiting",
    canceled: status === "canceled",
    stale: status === "stale",
    skipped: status === "skipped",
  })

  return <div className={statusClass}>{status}</div>
}
export default StepStatus
