import { SyntheticEvent, useEffect, useState } from "react"
import api from "@/api"
import { WorkspaceFile } from "@/types"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import Breadcrumps from "./Breadcrumbs"

import { FaDownload } from "react-icons/fa"
import ErrorBoundary from "../ErrorBoundary"

interface FileBrowserProps {
  workflowId: string
  path: string
  onPathChange: Function
  onOpenFile: Function
}

function FileBrowser({ workflowId, path, onPathChange, onOpenFile }: FileBrowserProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [files, setFiles] = useState<WorkspaceFile[]>([])
  useEffect(() => {
    setIsLoading(true)
    api.protected.listWorkspaceFiles(workflowId, path).then((files: WorkspaceFile[]) => {
      setFiles(files)
    })
    setIsLoading(false)
  }, [workflowId, path])

  const handleDownload = async (e: SyntheticEvent, workflowId: string, file: string) => {
    e.preventDefault()
    const token = await api.protected.getDownloadToken()

    const link = document.createElement("a")
    link.href = "/api/public/download/" + workflowId + file + "?token=" + token
    document.body.appendChild(link)
    link.click()
  }

  const onRowClick = (event: any) => {
    const file = event.data
    if (file.isDirectory) {
      onPathChange(file.url)
    }
  }

  if (isLoading) return <div>Loading...</div>

  const renderIcon = (file: WorkspaceFile) => {
    if (file.isDirectory) {
      return <i className="pi pi-folder"></i>
    } else {
      return <i className="pi pi-file"></i>
    }
  }

  const renderSize = (file: WorkspaceFile) => {
    if (!file.isDirectory) {
      return file.size
    }
    return "-"
  }

  const renderDate = (date: string) => {
    const options: any = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
    return new Intl.DateTimeFormat("de-CH", options).format(new Date(date))
  }

  const renderLastModifiedDate = (file: WorkspaceFile) => {
    return renderDate(file.lastModifiedAt)
  }

  const renderActions = (file: WorkspaceFile) => {
    // const regex = new RegExp(`.*/${workflowId}`)
    const regex = new RegExp(`.*/${workflowId}`)
    const filePath = file.url.replace(regex, "")
    return (
      <a title="Download" onClick={(e) => handleDownload(e, workflowId, filePath)}>
        <FaDownload />
      </a>
    )
  }
  return (
    //@ts-ignore
    <ErrorBoundary fallback={<div>Error in FileBrowser</div>}>
      <div>
        <Breadcrumps workflowId={workflowId} path={path} onPathChange={onPathChange} />
        <DataTable value={files} onRowClick={onRowClick}>
          <Column field="isDir" header="Icon" body={renderIcon}></Column>
          <Column field="name" header="Name" />
          <Column field="size" header="Size" body={renderSize} />
          <Column field="lastModifiedAt" header="Last modified at" body={renderLastModifiedDate} />
          <Column header="Actions" body={renderActions} />
        </DataTable>
      </div>
    </ErrorBoundary>
  )
}
export default FileBrowser
