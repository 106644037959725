import WorkflowMonitorList from "@/components/workflows/monitor/WorkflowMonitorList"
import Toolbar from "./Toolbar"
import styles from "./Toolbar.module.scss"


function WorkflowMonitor() {
  return (
    <div className={styles.content}>
      <Toolbar />
      <WorkflowMonitorList />
    </div>
  )
}
export default WorkflowMonitor
