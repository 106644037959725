import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primereact/autocomplete"
import { WorkflowState, WorkflowTemplate } from "@/types"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import { useState } from "react"
import { WorkflowQuery } from "./WorkflowListPage"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { FormEvent } from "primereact/ts-helpers"
import { formatISO, parseISO } from "date-fns"
import cx from "classnames"

import styles from "./WorkflowListFilter.module.scss"

interface Props {
  workflowTemplates: WorkflowTemplate[]
  query: WorkflowQuery
  onFilterChange: Function
}

interface WorkflowStatusOption {
  label: string
  value: WorkflowState | ""
}

function WorkflowListFilter({ workflowTemplates, query, onFilterChange }: Props) {
  const [workflowNames, setWorkflowNames] = useState<string[]>([])

  const searchWorkflowName = (event: AutoCompleteCompleteEvent) => {
    const query = event.query
    setWorkflowNames(
      workflowTemplates.map((wt) => wt.displayName).filter((name) => name.toLowerCase().includes(query.toLowerCase())),
    )
  }

  const handleWorkflowNameChange = (event: AutoCompleteChangeEvent) => {
    const newFilter = { ...query, workflowName: event.value }
    onFilterChange(newFilter)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter = { ...query, search: event.target.value }
    onFilterChange(newFilter)
  }

  const handleFileSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter = { ...query, fileSearch: event.target.value }
    onFilterChange(newFilter)
  }

  const handleDateChange = (event: FormEvent<Date>) => {
    const date = event.value
    if (date != null) {
      const dateString = formatISO(date, { representation: "date" })
      const newFilter = { ...query, date: dateString }
      onFilterChange(newFilter)
    }
  }

  const handleWorkflowStatusChange = (event: DropdownChangeEvent) => {
    const newFilter = { ...query, workflowStatus: event.value }
    onFilterChange(newFilter)
  }

  const handlerClear = () => {
    onFilterChange({ workflowName: "", search: "", date: "", workflowStatus: "", fileSearch: "" })
  }

  const dateValue = query.date ? parseISO(query.date) : null

  const workflowStatusOptions: WorkflowStatusOption[] = [
    { label: "\xa0", value: "" },
    { label: "Requested", value: "requested" },
    { label: "Listing", value: "listing" },
    { label: "Running", value: "running" },
    { label: "Completed", value: "completed" },
    { label: "Skipped", value: "skipped" },
    { label: "Failed", value: "failed" },
    { label: "Stale", value: "stale" },
  ]

  return (
    <div className={styles.fields}>
      <div className={styles.field}>
        <label>Workflow Name</label>
        <AutoComplete
          value={query.workflowName}
          suggestions={workflowNames}
          completeMethod={searchWorkflowName}
          onChange={handleWorkflowNameChange}
          dropdown
        />
      </div>
      <div className={cx(styles.field, styles.status)}>
        <label>Workflow Status</label>
        <Dropdown value={query.workflowStatus} options={workflowStatusOptions} onChange={handleWorkflowStatusChange} />
      </div>
      <div className={styles.field}>
        <label>Search</label>
        <InputText value={query.search} onChange={handleSearchChange} />
      </div>
      <div className={styles.field}>
        <label>Date</label>
        <Calendar value={dateValue} onChange={handleDateChange} dateFormat="dd.mm.yy" />
      </div>
      <div className={styles.field}>
        <label>File Search (in log files)</label>
        <InputText value={query.fileSearch} onChange={handleFileSearchChange} />
      </div>
      <div className={styles.actions}>
        <Button label="Clear" onClick={handlerClear} />
      </div>
    </div>
  )
}
export default WorkflowListFilter
