interface JsonMessage {
  type: string
  payload: object
}

let ws: any
let authToken: string
let reconnectCount = 0
let isAuthenticated = false

const messageHandlers: Set<any> = new Set()

const onOpenHandler = () => {
  console.log("websocket open handler")
  ws.send(JSON.stringify("Hello from client"))
}

const onCloseHandler = () => {
  console.log("websocket closed")
  console.log("starting reconnect")
  if (isAuthenticated) reconnect()
}

const onErrorHandler = (error: Error) => {
  // eslint-disable-next-line no-console
  console.error(`WebSocket error: ${JSON.stringify(error, ["message", "arguments", "type", "name"])}`)
  ws.close()
}

const onMessageHandler = async (message: any) => {
  const json = JSON.parse(message.data)
  if (json.type === "AUTHENTICATE") {
    const response = { type: "AUTHENTICATE", token: authToken }
    console.log("websocket authentication in progress")
    ws.send(JSON.stringify(response))
  } else if (json.type === "AUTHENTICATED") {
    isAuthenticated = true
    console.log("websocket authenticated")
  } else if (json.type === "AUTHENTICATION_FAILED") {
    console.error("websocket authentication failed")
    isAuthenticated = false
    ws.close()
  } else {
    messageHandlers.forEach((handler) => handler(json))
  }
}

const registerMessageHandler = (handler: any) => {
  console.log("websocket", "register on message handler")
  messageHandlers.add(handler)
}

const unregisterMessageHandler = (handler: any) => {
  console.log("websocket", "unregister on message handler")
  messageHandlers.delete(handler)
}

const open = async (token: string) => {
  if (!ws) {
    console.log("no websocket exists")
    const url = `wss://${window.location.hostname}/ws`
    authToken = token
    ws = new WebSocket(url)
    console.log("websocket created", ws)
    ws.onopen = onOpenHandler
    ws.onclose = onCloseHandler
    ws.onerror = onErrorHandler
    ws.onmessage = onMessageHandler
  }
}

const close = () => {
  ws.close()
}

const reconnect = () => {
  ws = null
  reconnectCount += 1
  console.log("websocket", "start reconnect timeout")
  setTimeout(() => {
    console.log("reconnect count", reconnectCount, messageHandlers.size)
    open(authToken)
  }, 2000)
}

const websocket = {
  open,
  close,
  registerMessageHandler,
  unregisterMessageHandler,
}

export default websocket
