interface TimestampProps {
  timestamp: number
}

const formatter = new Intl.DateTimeFormat("de-CH", {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  fractionalSecondDigits: 3,
})

function Timestamp({ timestamp }: TimestampProps) {
  return <div style={{ whiteSpace: "nowrap" }}>{formatter.format(timestamp)}</div>
}
export default Timestamp
