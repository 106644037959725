const authTokenKey = "authToken"

export const getAuthToken = () => {
  return sessionStorage.getItem(authTokenKey)
}

export const setAuthToken = (token: string) => {
  sessionStorage.setItem(authTokenKey, token)
}

export const deleteAuthToken = () => {
  sessionStorage.removeItem(authTokenKey)
}
