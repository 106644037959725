import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { WorkflowExecution, WorkflowTemplate, Workflow } from "@/types"
import api from "@/api"
import styles from "./TemplateList.module.scss"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import DisplayWorkflowTemplateList from "./DisplayTemplateWorkflowList"
import { ProgressSpinner } from "primereact/progressspinner"
import { InputText } from "primereact/inputtext"
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import { Tooltip } from "primereact/tooltip"
import config from "../config"

const TemplateList = () => {
  const [workflowExecutions, setWorkflowExecutions] = useState<Record<string, WorkflowExecution>>()
  const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplate[]>()

  const savedState = localStorage.getItem("templateFilter.checkBoxState");
  const [checkBoxState, setCheckBoxState] = useState(savedState ? JSON.parse(savedState) : ['All']);


  const [activeDDWorkflowValue, setActiveDDWorkflowValue] = useState<string>(
    localStorage.getItem("templateFilter.activeGroupValue") || "none",
  )

  const [search, setSearch] = useState<string>(localStorage.getItem("templateFilter.searchValue") || "")
  const navigate = useNavigate()

  interface DisplayType {
    label: string
    value: string
  }

  const displayWorkflowGroups: DisplayType[] = [
    { label: "None", value: "none" },
    { label: "Hotfolder", value: "hotfolder" },
    { label: "JournalDesigner", value: "journalDesigner" },
    { label: "ASE-Server", value: "aseServer" },
    { label: "ASE-Server IPS", value: "aseServerIps" },
    { label: "GDR Goldbach", value: "gdrGoldbach" },
    { label: "GDR Emergency", value: "gdrEmergency" },
    { label: "GDR Tamedia", value: "gdrTamedia" },
    { label: "GDR Service", value: "gdrService" },
    { label: "GDR 20Minuten", value: "gdr20Minuten" },
    { label: "Sternwald Prod", value: "sternwaldProd" },
    { label: "Sternwald Test", value: "sternwaldTest" },
  ]

  useEffect(() => {
    const loadExecutions = async () => {
      const executions = await api.protected.getWorkflowExecutions()
      setWorkflowExecutions(executions)
    }
    if (workflowTemplates) loadExecutions()
  }, [workflowTemplates])

  useEffect(() => {
    const loadTemplates = async () => {
      const templates = await api.protected.getWorkflowTemplates()
      setWorkflowTemplates(templates)
    }
    loadTemplates()
  }, [])

  if (!workflowTemplates || !workflowExecutions) return <ProgressSpinner />

  const groupTemplates = (templates: WorkflowTemplate[]): Record<string, WorkflowTemplate[]> => {
    return templates.reduce((groups: Record<string, WorkflowTemplate[]>, template: WorkflowTemplate) => {
      const firstWord: string = (template.displayName || "other").trim().split(" ")[0].toLowerCase()
      if (!groups[firstWord]) {
        groups[firstWord] = [template]
      } else {
        groups[firstWord].push(template)
      }
      return groups
    }, {})
  }

  //search templates filter
  const searchInTemplateObject = (template: WorkflowTemplate, searchText: string): boolean => {
    if (!searchText.includes(":")) {
      return searchWithoutKey(template, searchText.trim().toLowerCase()) // Search normally if no key specified
    }
    const [key, value] = searchText.split(":").map((s) => s.trim().toLowerCase())
    if (typeof template !== "object" || template === null) {
      return false
    }
    if (Array.isArray(template)) {
      return template.some((entry) => searchInTemplateObject(entry, searchText))
    }
    return Object.entries(template).some(([objKey, objValue]) => {
      const matchKey = objKey.toLowerCase() === key
      if (typeof objValue === "object") {
        return matchKey || searchInTemplateObject(objValue, searchText)
      }
      const matchValue = String(objValue).toLowerCase().includes(value)
      return matchKey && matchValue
    })
  }

  const searchWithoutKey = (template: WorkflowTemplate, searchText: string): boolean => {
    function recursiveSearch(currentTemplate: WorkflowTemplate): boolean {
      if (Array.isArray(currentTemplate)) {
        return currentTemplate.some((entry) => recursiveSearch(entry))
      } else if (typeof currentTemplate === "object" && currentTemplate !== null) {
        return Object.values(currentTemplate).some((value) => {
          if (typeof value === "object") {
            return recursiveSearch(value)
          }
          return String(value).toLowerCase().includes(searchText)
        })
      }
      return false
    }

    return recursiveSearch(template)
  }

  //grouped templates dropdown filter
  const groupWorkflowsWithSpecificOptions = (templates: Workflow[]) => {

    let searchString = ""
    for (const types of displayWorkflowGroups) {
      if (types.value === activeDDWorkflowValue) {
        searchString = config[types.value]
      }
    }
    return templates.filter((template) => containsSpecificOptions(template, searchString))
  }
  
  const containsSpecificOptions = (template: Workflow, searchString: string): boolean => {
    if (!searchString)  return true

    return template.steps.some((step) => {
      if (step.data && step.data.fileLists && Array.isArray(step.data.fileLists)) {
        return step.data.fileLists.some((fileList: { source?: string; target?: string }) => {
          const sourceMatch = fileList.source && fileList.source.includes(searchString)
          const targetMatch = fileList.target && fileList.target.includes(searchString)
          return sourceMatch || targetMatch
        })
      }
      // return false
    })
  }

  const activeTemplates = workflowTemplates.filter((template) => {
    if (checkBoxState.length === 0) {
      return true // Show all templates when no filter is applied
    }
    if (checkBoxState.includes("All")) {
      // If "All" is selected, return all templates
      return true
    }

    let isMatch = true

    // Check for Active/Inactive
    if (checkBoxState.includes("Active") && !template.isActive) {
      isMatch = false
    }
    if (checkBoxState.includes("Inactive") && template.isActive) {
      isMatch = false
    }

    // Check for Enabled/Disabled
    if (checkBoxState.includes("Enabled") && !template.isEnabled) {
      isMatch = false
    }
    if (checkBoxState.includes("Disabled") && template.isEnabled) {
      isMatch = false
    }

    // Check for Visible/Invisible
    if (checkBoxState.includes("Visible") && !template.isVisible) {
      isMatch = false
    }
    if (checkBoxState.includes("Invisible") && template.isVisible) {
      isMatch = false
    }

    return isMatch
  })
  const searchresult = Object.values(activeTemplates).filter((template) => {
    return searchInTemplateObject(template, search)
  })
  

  const specificWorkflowGroups = groupWorkflowsWithSpecificOptions(searchresult as unknown as Workflow[])

  const groupedTemplates = groupTemplates(specificWorkflowGroups as unknown as WorkflowTemplate[])

  const handleNew = () => {
    navigate("/app/templates/new")
  }

  const onCheckBoxChange = (event: CheckboxChangeEvent) => {
    const { value, checked } = event
    let state = [...checkBoxState]

    if (value === "All" && checked) {
      // If 'All' is checked, clear all other selections
      state = ["All"]
    } else {
      // Remove 'All' if any other checkbox is checked
      if (state.includes("All")) {
        state = state.filter((item) => item !== "All")
      }

      if (checked) {
        state.push(value)

        // Mutually exclusive logic for Active/Inactive, Enabled/Disabled, Visible/Invisible
        if (value === "Active") {
          state = state.filter((item) => item !== "Inactive")
          if (!state.includes("Active")) state.push("Active")
        }
        if (value === "Inactive") {
          state = state.filter((item) => item !== "Active")
          if (!state.includes("Inactive")) state.push("Inactive")
        }

        if (value === "Enabled") {
          state = state.filter((item) => item !== "Disabled")
          if (!state.includes("Enabled")) state.push("Enabled")
        }
        if (value === "Disabled") {
          state = state.filter((item) => item !== "Enabled")
          if (!state.includes("Disabled")) state.push("Disabled")
        }

        if (value === "Visible") {
          state = state.filter((item) => item !== "Invisible")
          if (!state.includes("Visible")) state.push("Visible")
        }
        if (value === "Invisible") {
          state = state.filter((item) => item !== "Visible")
          if (!state.includes("Invisible")) state.push("Invisible")
        }
      } 
    }

    // Ensure 'All' is not manually turned off
    if (state.length === 0) {
      state = ["All"]
    }

    setCheckBoxState(state)
    localStorage.setItem("templateFilter.checkBoxState", JSON.stringify(state))
  }

  const handleFilterWorkflowChange = (event: DropdownChangeEvent) => {
    setActiveDDWorkflowValue(event.value)
    localStorage.setItem("templateFilter.activeGroupValue", event.value)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
    localStorage.setItem("templateFilter.searchValue", event.target.value)
  }
  const handleClear = (): void => {
    setSearch("")
    localStorage.setItem("templateFilter.searchValue", "")
  }
  const renderCheckboxes = (): JSX.Element => {
    return (
      <div className={styles.cronToggleGroup}>
        <div className={styles.cronToggle}>
          <div className="flex align-items-center">
            <Checkbox
              inputId="all"
              name="all"
              value="All"
              onChange={onCheckBoxChange}
              checked={checkBoxState.includes("All")}
            />
            <label style={{ margin: "10px", color: "black" }} htmlFor="all" className="ml-2">
              All
            </label>
          </div>
        </div>
        <div className={styles.cronToggle}>
          <div className="flex align-items-center">
            <Checkbox
              inputId="active"
              name="active"
              value="Active"
              onChange={onCheckBoxChange}
              checked={checkBoxState.includes("Active")}
            />
            <label style={{ margin: "10px", color: "black" }} htmlFor="active" className="ml-2">
              Active
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="inactive"
              name="inactive"
              value="Inactive"
              onChange={onCheckBoxChange}
              checked={checkBoxState.includes("Inactive")}
            />
            <label style={{ margin: "10px", color: "black" }} htmlFor="inactive" className="ml-2">
              Inactive
            </label>
          </div>
        </div>
        <div className={styles.cronToggle}>
          <div className="flex align-items-center">
            <Checkbox
              inputId="enabled"
              name="enabled"
              value="Enabled"
              onChange={onCheckBoxChange}
              checked={checkBoxState.includes("Enabled")}
            />
            <label style={{ margin: "10px", color: "black" }} htmlFor="enabled" className="ml-2">
              Enabled
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="disabled"
              name="disabled"
              value="Disabled"
              onChange={onCheckBoxChange}
              checked={checkBoxState.includes("Disabled")}
            />
            <label style={{ margin: "10px", color: "black" }} htmlFor="disabled" className="ml-2">
              Disabled
            </label>
          </div>
        </div>
        <div className={styles.cronToggle}>
          <div className="flex align-items-center">
            <Checkbox
              inputId="visible"
              name="visible"
              value="Visible"
              onChange={onCheckBoxChange}
              checked={checkBoxState.includes("Visible")}
            />
            <label style={{ margin: "10px", color: "black" }} htmlFor="visible" className="ml-2">
              Visible
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="invisible"
              name="invisible"
              value="Invisible"
              onChange={onCheckBoxChange}
              checked={checkBoxState.includes("Invisible")}
            />
            <label style={{ margin: "10px", color: "black" }} htmlFor="invisible" className="ml-2">
              Invisible
            </label>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.contentTemplates}>
      <div className={styles.filter}>
          <div className={styles.toggleGroups}>
            <div className={styles.filterLabel}>Search templates</div>
            <InputText
              type="text"
              placeholder="enter search string"
              value={search}
              tooltip="enter a string for a fulltext search or use a ':' for specific key ex.: 'handler: download'"
              tooltipOptions={{ showDelay: 300, hideDelay: 0 }}
              onChange={handleSearchChange}
            />
            <Button label="Clear Search" style={{ marginLeft: "10px" }} onClick={() => handleClear()} />
            <div className={styles.divider}></div>
            <div className={styles.filterLabel} style={{ margin: "0 0 0 10px" }}>
              <label
                className="custom-target"
                style={{ margin: "10px", color: "black" }}
                data-pr-tooltip="Shows templates that have common sources and targets."
                data-pr-showdelay={300}
              >
                Grouped templates
              </label>
            </div>
            <Tooltip target=".groupedTemplates" mouseTrack mouseTrackLeft={10} />
            <Dropdown
              inputId="workflow-filter"
              value={activeDDWorkflowValue}
              onChange={(e) => handleFilterWorkflowChange(e)}
              options={displayWorkflowGroups}
              style={{ margin: "0 0 0 10px" }}
            />
          </div>
          <div className={styles.toggleGroups}>
            <Button
              className="newWorkflowButton"
              icon="pi pi-plus"
              onClick={handleNew}
              size="small"
              data-pr-tooltip="New Workflow Template"
              data-pr-showdelay={300}
            />
            <Tooltip target=".newWorkflowButton" mouseTrack mouseTrackLeft={10} />
            <div className={styles.divider}></div>
            <label style={{ margin: "18px", color: "black" }}>Display templates</label>
            {renderCheckboxes()}
          </div>
      </div>
      <DisplayWorkflowTemplateList
        groupedTemplates={groupedTemplates}
        workflowExecutions={workflowExecutions}
        groupFilter={activeDDWorkflowValue}
      />
    </div>
  )
}

export default TemplateList
