import Bytes from "@/components/Bytes"
import Duration from "@/components/Duration"
import { Workflow, WorkflowStep } from "@/types"
import { Button } from "primereact/button"
import StepStatus from "../StepStatus"

import styles from "./StepList.module.scss"

interface StepListProps {
  workflow: Workflow
  onRerunFailedStep: (step: WorkflowStep) => void
}

function StepList({ workflow, onRerunFailedStep }: StepListProps) {
  return (
    <section className={styles.steps}>
      <table className={styles.stepsTable}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Name</th>
            <th>Tenant</th>
            <th>Progress</th>
            <th className={styles.numeric}>Duration</th>
            <th className={styles.numeric}>Files</th>
            <th className={styles.numeric}>Size</th>
            <th className={styles.numeric}>Retries</th>
            <th>Message</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {workflow.steps.map((step) => {
            return (
              <tr key={step.id}>
                <td>
                  <StepStatus step={step} />
                </td>
                <td>{step.name}</td>
                <td>{step.tenant}</td>
                <td>
                  <meter value={step.progress} />
                </td>
                <td className={styles.numeric}>
                  <Duration duration={step.statistics?.duration} />
                </td>
                <td className={styles.numeric}>{step.statistics?.fileCount}</td>
                <td className={styles.numeric}>
                  <Bytes bytes={step.statistics?.totalFileSize} />
                </td>
                <td className={styles.numeric}>{step.executionCount >= 1 ? step.executionCount - 1 : 0}</td>
                <td>{step.data?.error}</td>
                <td>
                  {step.state === "failed" ||
                    (step.state === "stale" && (
                      <Button
                        icon="pi pi-replay"
                        className={styles.rerunButton}
                        severity="warning"
                        label="Re-run"
                        onClick={() => onRerunFailedStep(step)}
                      />
                    ))}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}
export default StepList
