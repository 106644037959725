class ApiError extends Error {
  statusCode: number | undefined
  constructor(message: string, statusCode: number | undefined) {
    super(message)
    this.name = 'ApiError'
    this.statusCode = statusCode
  }
}

export default ApiError

