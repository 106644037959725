import { WorkflowTemplate } from "@/types"
import { dump as objec2Yaml } from "js-yaml"

import { highlight, languages } from "prismjs"
import "prismjs/components/prism-yaml"
import "prismjs/themes/prism.css"

interface TemplateProps {
  template: WorkflowTemplate
}

function Template({ template }: TemplateProps) {
  const yaml = objec2Yaml({ ...template }, { quotingType: '"', forceQuotes: true })
  const highlighted = highlight(yaml, languages.yaml, "yaml")

  return (
    <section>
      <pre>
        <code className="language-yaml" dangerouslySetInnerHTML={{ __html: highlighted }}></code>
      </pre>
    </section>
  )
}
export default Template
