import { Column, ColumnFilterElementTemplateOptions } from "primereact/column"
import { DataTable, DataTableFilterMeta } from "primereact/datatable"
import { FilterMatchMode } from "primereact/api"
import FileOperation from "./FileOperation"
import LogLevel from "./LogLevel"
import Timestamp from "./Timestamp"
import { useState } from "react"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"

import Duration from "../Duration"

import styles from "./LogTable.module.scss"
import Bytes from "../Bytes"
import { Workflow, WorkflowTemplate } from "@/types"
interface LogTableProps {
  log: Array<Record<string, string>>
  template: WorkflowTemplate
  workflow: Workflow
}

const logLevelTemplate = (line: Record<string, unknown>) => {
  return <LogLevel level={line.level as number} />
}

const fileOperationTemplate = (line: Record<string, unknown>) => {
  return <FileOperation operation={line.op as string} />
}

const timestampTemplate = (line: Record<string, unknown>) => {
  return <Timestamp timestamp={line.time as number} />
}

const durationTemplate = (line: Record<string, unknown>) => {
  return <Duration duration={line.duration as number} />
}

const sizeTemplate = (line: Record<string, unknown>) => {
  return <Bytes bytes={line.size as number} />
}

const handlerTemplate = (line: Record<string, unknown>, workflow: Workflow) => {
  const step = workflow?.steps?.find((step) => step.id === line.stepId)
  return (
    <div>
      <div style={{ whiteSpace: "nowrap" }}>{step?.name}</div>
      <div style={{ whiteSpace: "nowrap" }}>{line.handlerId as string}</div>
    </div>
  )
}

const rowGroupHeaderTemplate = (line: Record<string, unknown>, workflow: Workflow) => {
  const step = workflow.steps.find((step) => step.id === line.stepId)
  return (
    <div>
      {step?.name} {line.stepId as string}
    </div>
  )
}

function LogTable({ log, workflow, template }: LogTableProps) {
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // tenant: { value: null, matchMode: FilterMatchMode.EQUALS },
  })
  const [globalFilterValue, setGlobalFilterValue] = useState("")

  const availableTenants = Array.from(
    log.reduce((tenants, line) => {
      tenants.add(line.tenant as string)
      return tenants
    }, new Set<string>()),
  )

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value
    let _filters: any = { ...filters }

    _filters["global"].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  // const tenantFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  //   return (
  //     <Dropdown
  //       value={options.value}
  //       options={availableTenants}
  //       onChange={(e: DropdownChangeEvent) => options.filterApplyCallback(e.value)}
  //       placeholder="Select Tenant"
  //       className="p-column-filter"
  //       showClear
  //     />
  //   )
  // }

  const renderHeader = () => {
    return (
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
      </IconField>
    )
  }

  return (
    <DataTable
      value={log}
      header={renderHeader()}
      // groupRowsBy="stepId"
      // rowGroupMode="subheader"
      // rowGroupHeaderTemplate={(line: Record<string, unknown>) => rowGroupHeaderTemplate(line, workflow)}
      filters={filters}
      //filterDisplay="row"
      globalFilterFields={["tenant", "msg", "source", "target"]}
    >
      <Column field="level" header="Level" body={logLevelTemplate} />
      <Column field="time" header="Time" body={timestampTemplate} />
      {/* <Column field="tenant" header="Tenant" filter filterElement={tenantFilterTemplate} showFilterMenu={false} /> */}
      <Column field="tenant" header="Tenant" />
      <Column
        field="handlerId"
        header="Handler"
        body={(line: Record<string, unknown>) => handlerTemplate(line, workflow)}
      />
      <Column field="op" header="Operation" body={fileOperationTemplate} />
      <Column field="duration" align="right" header="Duration [ms]" body={durationTemplate} />
      <Column field="size" align="right" header="Size [byte]" body={sizeTemplate} />
      <Column field="length" align="right" header="Length" />
      {/* <Column field="sourceUrl" header="Source" />
      <Column field="targetUrl" header="Target" /> */}

      <Column field="msg" header="Message" />
    </DataTable>
  )
}
export default LogTable
