export default {
    none: "",
    hotfolder: "sftp://hotFolder@hotfolder.ness.tamedia.ch",
    journalDesigner: "jd.ness.tamedia.ch",
    aseServer: "@txg01.cnames.alfa-cloud.net:8104",
    aseServerIps: "@txg01.cnames.alfa-cloud.net:8106",
    gdrGoldbach: "gdr://gdrTecGoldbach@tec-goldbach-workflow",
    gdrEmergency: "gdr://gdrTecEmergency@tec-emergency-workflow",
    gdr20Minuten: "gdr://gdrTec20m@tec-20minuten-workflow",
    sternwaldProd: "@ftp.tam.prd.sternwald.cloud/fs-0326c8872a230d592",
    sternwaldTest: "@ftp.stg.sternwald.cloud/fs-0862e4f545f110e98",
}