import prettyBytes from "pretty-bytes"

interface BytesProps {
  bytes: number | undefined | null
}

function Bytes({ bytes }: BytesProps) {
  if (bytes == null) return null

  return <div>{prettyBytes(bytes)}</div>
}
export default Bytes
