import { Workflow, WorkflowStep } from "@/types"

import styles from "./WorkflowProgress.module.scss"

interface WorkflowProgressProps {
  workflow: Workflow
}

function WorkflowProgress({ workflow }: WorkflowProgressProps) {
  if (workflow == null) return null

  let stepCount = workflow.steps.length
  let completedStepCount = workflow.steps.filter(
    (step: WorkflowStep) => step.state === "completed" || step.state === "skipped",
  ).length

  let value = completedStepCount / stepCount

  return (
    <div className={styles.bar}>
      <meter value={value} />
    </div>
  )
}
export default WorkflowProgress
