import { Outlet } from "react-router-dom"
import styles from "./AppLayout.module.scss"
import Header from "./components/layout/Header"
import { ReactNode } from "react"

interface Props {
  children?: ReactNode
}

const AppLayout = ({ children }: Props) => {
  return (
    <div className={styles.appLayout}>
      <Header />
      <div className={styles.content}>{children || <Outlet />}</div>
    </div>
  )
}

export default AppLayout
