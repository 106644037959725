import styles from "./SearchLog.module.scss"
import Timestamp from "../details/Timestamp"

interface Props {
  fileSearchMatches: Record<string, string>[] | undefined
}
const SearchLog = ({ fileSearchMatches }: Props) => {
  const renderResult = fileSearchMatches?.map((element: any, i) => (
    <section key={i} className={styles.tabs}>
      <div className={styles.tab}>
        <Timestamp timestamp={element?.time} />
      </div>
      <div className={styles.tab}>{element?.targetUrl}</div>
    </section>
  ))

  return (
    <div>
      <div>{renderResult}</div>
    </div>
  )
}

export default SearchLog
