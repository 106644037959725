import { format, parseISO } from "date-fns"

function normalize(date: Date | string) {
  let toFormat: Date
  if (typeof date === "string") {
    toFormat = parseISO(date)
  } else {
    toFormat = date
  }

  return toFormat
}

export const formatDateTime = (date?: Date | string) => {
  if (!date) return
  let toFormat = normalize(date)
  return format(toFormat, "dd.MM.yyyy HH:mm:ss")
}

export const formatDate = (date?: Date | string) => {
  if (!date) return
  let toFormat = normalize(date)
  return format(toFormat, "dd.MM.yyyy")
}

export const formatTime = (date?: Date | string) => {
  if (!date) return
  let toFormat = normalize(date)
  return format(toFormat, "HH:mm:ss")
}
