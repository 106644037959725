import { useEffect, useState } from "react"
import { useOktaAuth } from "@okta/okta-react"
import { toRelativeUrl } from "@okta/okta-auth-js"
import { Outlet } from "react-router-dom"
import { deleteAuthToken, setAuthToken } from "./OktaHelper"
import LoginSpinner from "@/components/LoginSpinner"

export const ProtectedRoute = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const [storedAuthToken, setStoredAuthToken] = useState<string>()

  useEffect(() => {
    // we store the auth token in sessionStorage, so that we don't need to pass it every api function
    const token = authState?.accessToken?.accessToken
    if (token) {
      setAuthToken(token)
      setStoredAuthToken(token)
    } else {
      deleteAuthToken()
      setStoredAuthToken(undefined)
    }
  }, [authState?.accessToken?.accessToken])

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated])

  if (!authState?.isAuthenticated || !storedAuthToken) {
    return <LoginSpinner />
  }

  return <Outlet />
}

export default ProtectedRoute
