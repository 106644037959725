import { useEffect, useState } from "react"
import {  WorkflowTemplate } from "@/types"
import { EditorView, Decoration, DecorationSet } from "@codemirror/view"
import { StateField, Transaction, EditorState } from "@codemirror/state"
import { StreamLanguage } from "@codemirror/language"
import { yaml } from "@codemirror/legacy-modes/mode/yaml"
import { dump as objec2Yaml } from "js-yaml"
import styles from "./Template.module.scss"
import CodeMirror from "@uiw/react-codemirror"

const CodeMirrorComponent = ({
  template,
  handleEditorChange,
  setIsEdited,
}: {
  template: WorkflowTemplate
  handleEditorChange: (value: string) => void
  setIsEdited: (edited: boolean) => void; // Notify parent about edit state
}) => {
  const [editorView, setEditorView] = useState<EditorView | null>(null)
  const [isInitializing, setIsInitializing] = useState(true);

  // Function to generate decorations based on the current editor content
  const generateDecorations = (doc: EditorState['doc']): DecorationSet => {
    const decorations = [];
    let inBlock = false;
    let currentColorClass = styles.highlightBlock1; // Start with the first color class

    for (let i = 1; i <= doc.lines; i++) {
      const line = doc.line(i); // line numbers are 1-based

      if (line.text.includes('- name:')) {
        if (inBlock) {
          // Toggle the color for the next block
          currentColorClass =
            currentColorClass === styles.highlightBlock1
              ? styles.highlightBlock2
              : styles.highlightBlock1;
        }
        inBlock = true;
      }

      if (inBlock) {
        decorations.push(Decoration.line({ class: currentColorClass }).range(line.from));
      }
    }

    return Decoration.set(decorations);
  };

  // Custom decoration to highlight lines containing "- name:" blocks
  const highlightNameBlock = StateField.define<DecorationSet>({
    create(state) {
      return generateDecorations(state.doc);
    },
    update(decorations, transaction: Transaction) {
      if (!transaction.docChanged) return decorations;
      return generateDecorations(transaction.state.doc);
    },
    provide: (field) => EditorView.decorations.from(field),
  });

  useEffect(() => {
    if (editorView) {
      // Update the editor content and reapply decorations after fetch
      const newContent = objec2Yaml({ ...template, version: undefined, lastEditedBy: undefined }, { quotingType: '"', forceQuotes: true })
      editorView.dispatch({
        changes: { from: 0, to: editorView.state.doc.length, insert: newContent },
      })
      setIsInitializing(true); // Set the flag when content is initially loaded
      setIsEdited(false); // Reset edited state when new content is loaded
    }
  }, [template, editorView])

  // Initialize the editor with state and effects
  const onEditorReady = (view: EditorView) => {
    setEditorView(view)
  }

  const onChange = (value: string) => {
    if (isInitializing) {
      setIsInitializing(false);
    } else {

      handleEditorChange(value);
      setIsEdited(true); 
    }
  };

  return (
    <CodeMirror
      value={objec2Yaml({ ...template, version: undefined, lastEditedBy: undefined }, { quotingType: '"', forceQuotes: true })}
      className={styles.editor}
      extensions={[StreamLanguage.define(yaml), highlightNameBlock]}
      onChange={onChange}
      onCreateEditor={onEditorReady} // Capture the initial editor view
    />
  )
}

export default CodeMirrorComponent
