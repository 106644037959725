import { ToastParams } from "@/types"
import { Toast } from "primereact/toast"
import { RefObject, useCallback } from "react"

const useToast = (toastRef: RefObject<Toast>) => {
  const showError = useCallback(({ msg, title = "Error" }: ToastParams) => {
    toastRef?.current?.show({ severity: "error", summary: title, detail: msg, life: 10_000 })
  }, [])

  const showSuccess = useCallback(({ msg, title = "Success" }: ToastParams) => {
    toastRef?.current?.show({ severity: "success", summary: title, detail: msg, life: 3_000 })
  }, [])

  const showWarning = useCallback(({ msg, title = "Warning" }: ToastParams) => {
    toastRef?.current?.show({ severity: "warn", summary: title, detail: msg, life: 5_000 })
  }, [])

  const showInfo = useCallback(({ msg, title = "Info" }: ToastParams) => {
    toastRef?.current?.show({ severity: "info", summary: title, detail: msg, life: 5_000 })
  }, [])

  return { showError, showSuccess, showWarning, showInfo }
}

export default useToast
