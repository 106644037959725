import { Workflow, WorkflowState, WorkflowStatistics } from "@/types"

const getWorkflowStatistics = (workflow: Workflow): WorkflowStatistics => {
  const requestedEvent = workflow.events.find((e) => e.type === "workflow-requested")
  const completedEvent = workflow.events.find((e) => e.type === "workflow-completed")

  const startedAt = requestedEvent?.timestamp ? new Date(requestedEvent.timestamp) : undefined
  const completedAt = completedEvent?.timestamp ? new Date(completedEvent.timestamp) : undefined

  const duration = startedAt && completedAt ? completedAt.getTime() - startedAt.getTime() : undefined

  return { startedAt, completedAt, duration }
}

const getStatus = (workflow: Workflow): WorkflowState => {
  let status: WorkflowState = "requested"

  if (!workflow) return status

  const stateCount: Record<string, number> = {}
  const totalSteps = workflow.steps.length

  if (totalSteps === 1 && workflow.steps[0].generateLists) {
    if (workflow.steps[0].state === "completed") {
      return "skipped"
    } else if (workflow.steps[0].state === "running") {
      return "listing"
    }
  }

  workflow.steps.forEach((step) => {
    const status = step.state
    if (stateCount[status]) {
      stateCount[step.state] = stateCount[step.state] + 1
    } else {
      stateCount[step.state] = 1
    }
  })

  const skippedCount = stateCount.skipped || 0

  if (workflow.steps.length > 1) {
    status = "running"
  }

  if (skippedCount > 0 && totalSteps - skippedCount === workflow.tenants.length) {
    // Todo: remove when all old skipped WF are archived
    status = "skipped" // all steps were skipped except the first one (per tenant)
  } else if (skippedCount + stateCount.completed === totalSteps) {
    status = "completed"
  } else if (stateCount.failed > 0) {
    status = "failed"
  } else if (stateCount.stale > 0 || workflow.isStale) {
    status = "stale"
  }

  return status
}

export default { getWorkflowStatistics, getStatus }
