import React from 'react';
import styles from "./DisplayTemplateWorkflowList.module.scss"


interface ToggleButtonProps {
  value: boolean;
  onChange: (value: boolean | undefined) => void;
  disabled?: boolean;
}
const ToggleButton: React.FC<ToggleButtonProps> = ({ value, onChange }) => {
  const handleClick = () => {
    onChange(!value);
  };
  return (
    <div
      className={`${styles.toggleButton} ${value ? styles.on : styles.off}`}
      onClick={handleClick}
      role="switch"
      aria-checked={value}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleClick();
        }
      }}
    >
      <div className={styles.toggleHandle}>
      </div>
    </div>
  );
};

export default ToggleButton;