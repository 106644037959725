import { useCallback, useEffect, useState } from "react"
import styles from "./WorkflowMonitorList.module.scss"
import { Paginator } from "primereact/paginator"
import WorkflowMonitorRow from "./WorkflowMonitorRow"
import workflowStore from "@/api/workflowStore"

function WorkflowList() {
  const [paginationFirstIndex, setPaginationFirstIndex] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(parseInt(localStorage.getItem("workflowlist.pageSize") || "10"))
  const [workflowIds, setWorkflowIds] = useState<string[]>([])

  const onListUpdate = useCallback((workflowIds: string[]) => {
    setWorkflowIds(workflowIds)
  }, [])

  useEffect(() => {
    workflowStore.subscribeIdList(onListUpdate)
    return () => {
      workflowStore.unsubscribeIdList(onListUpdate)
    }
  }, [])

  const sortedList = workflowIds.sort((a: string, b: string) => {
    return a > b ? -1 : a < b ? 1 : 0
  })

  const slicedList = sortedList.slice(paginationFirstIndex, paginationFirstIndex + pageSize)

  const onPageChange = (event: any) => {
    setPaginationFirstIndex(event.first)
    setPageSize(event.rows)
    localStorage.setItem("workflowlist.pageSize", event.rows)
  }
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Status</th>
            <th>ID</th>
            <th>Name</th>
            <th>Version</th>
            <th>Progress</th>
            <th>Timestamp</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {slicedList.map((workflowId: string) => {
            return <WorkflowMonitorRow key={workflowId} workflowId={workflowId} />
          })}
        </tbody>
      </table>
      <Paginator
        first={paginationFirstIndex}
        rows={pageSize}
        totalRecords={workflowIds.length}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      />
    </>
  )
}
export default WorkflowList
