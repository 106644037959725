import { Tag } from "primereact/tag"
import styles from "./LogLevel.module.scss"

interface LogLevelProps {
  level: number
}

type Severity = "info" | "warning" | "danger"

const severities: Record<number, Severity> = {
  30: "info",
  40: "warning",
  50: "danger",
}

const names: Record<number, string> = {
  30: "INFO",
  40: "WARNING",
  50: "ERROR",
}

function LogLevel({ level }: LogLevelProps) {
  const severity = severities[level] || "info"
  const name = names[level] || "UNKNOWN"

  return (
    <div className={styles[`log-level-${level}`]}>
      <Tag severity={severity} value={name} rounded />
    </div>
  )
}
export default LogLevel
