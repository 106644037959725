import api from "@/api"
import { TemplateValidationResults, WorkflowTemplate } from "@/types"
import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { dump as objec2Yaml, load as yaml2Object } from "js-yaml"
import styles from "./Template.module.scss"
import { Button } from "primereact/button"
import debounce from "lodash.debounce"
import { Message } from "primereact/message"
import ToastContext from "@/ToastContext"
import Timestamp from "./workflows/details/Timestamp"
import  CodeMirrorComponent  from "./TemplateCodeEditor"

const Template = () => {
  const [template, setTemplate] = useState<WorkflowTemplate>()
  const [editedTemplate, setEditedTemplate] = useState<WorkflowTemplate>()
  const [validationResults, setValidationResults] = useState<TemplateValidationResults>()
  const [cronExecutions, setCronExecutions] = useState<string[]>([])
  const [parsingError, setParsingError] = useState<any>()
  const [isEdited, setIsEdited] = useState(false); 
  const { templateId } = useParams()
  const navigate = useNavigate()
  const { showError, showSuccess } = useContext(ToastContext)

  const isValid = validationResults?.errors?.length === 0
  const hasErrors = (validationResults?.errors || []).length > 0
  const hasWarnings = (validationResults?.warnings || []).length > 0
  const hasCronExecutions = cronExecutions?.length > 0

  useEffect(() => {
    const loadTemplate = async () => {
      const result = await api.protected.getWorkflowTemplate(templateId!)
      setTemplate(result.template)
      setEditedTemplate(result.template)
      setCronExecutions(result.cronExecutions)
    }
    if (templateId) loadTemplate()
  }, [templateId])

  if (!template) return null

  const doValidate = async (templateToValidate: WorkflowTemplate) => {
    if (!templateToValidate) return
    const result = await api.protected.validateWorkflowTemplate(templateToValidate)
    setParsingError(undefined)
    setValidationResults(result)
    setCronExecutions(result.cronExecutions)
  }

  const saveTemplate = async () => {
    let saved
    if (editedTemplate) {
      try {
        if (!template.version) {
          saved = await api.protected.createWorkflowTemplate(editedTemplate)
          navigate(`/app/templates/${saved.name}`)
        } else {
          saved = await api.protected.updateWorkflowTemplate(template.name, template.version, editedTemplate)
          setTemplate(saved)
          showSuccess({ msg: "Template has been saved" })
        }
      } catch (e: any) {
        showError({ msg: e.message })
      }
    }
  }

  const handleEditorChange = async (value: string) => {
    setValidationResults(undefined)
    setParsingError(undefined)
    processChange(value)
  }

  const processChange = debounce(async (value: string) => {
    let templateObject
    try {
      templateObject = yaml2Object(value) as WorkflowTemplate
      setEditedTemplate(templateObject)
      await doValidate(templateObject)
    } catch (e: any) {
      setParsingError(e)
      setValidationResults(undefined)
    }
  }, 1000)

  const renderParsingError = () => {
    return (
      <div className={styles.parsingError}>
        <h3>
          <i className="pi pi-exclamation-triangle" style={{ fontSize: "1.5rem" }} /> YAML parsing error
        </h3>
        {parsingError.message}
      </div>
    )
  }

  const renderErrors = () => {
    return validationResults!.errors!.map((e, i) => (
      <Message
        key={i}
        style={{
          borderWidth: "0 0 0 6px",
        }}
        severity="error"
        text={e}
      ></Message>
    ))
  }

  const renderWarnings = () => {
    return validationResults!.warnings!.map((e, i) => (
      <Message
        key={i}
        style={{
          borderWidth: "0 0 0 6px",
        }}
        severity="warn"
        text={e}
      ></Message>
    ))
  }

  const renderCronExecutions = () => {
    const content = (
      <div className={styles.cronExcutionsContent}>
        <div className={styles.cronIntro}>
          Shows the next executions of this workflow based on your trigger.cron expression. We use <em>cronjs</em> for
          cron expression parsing. Please check the{" "}
          <a href="https://github.com/datasert/cronjs#cron-expressions" target="_blank">
            documentation
          </a>{" "}
          for details.
        </div>
        <ul>
          {cronExecutions.map((execution) => {
            return (
              <li>
                <Timestamp timestamp={execution} includeDayName />
              </li>
            )
          })}
        </ul>
      </div>
    )

    return (
      <div className={styles.cronExecutions}>
        <h3>Next cron executions</h3>
        <div className={styles.cronExecutionsContainer}>
          <Message
            // key={i}
            style={{
              borderWidth: "0 0 0 6px",
            }}
            severity="info"
            content={content}
          ></Message>
        </div>
      </div>
    )
  }

  const renderValidMessage = () => {
    return (
      <>
        <Message
          style={{
            borderWidth: "0 0 0 6px",
          }}
          severity="success"
          text="YAML is valid"
        ></Message>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <h3>
        <Link to="/app/templates">Templates</Link> <i className="pi pi-chevron-right" /> {templateId}
        {template.version && <span>Version {template.version || "new"}</span>} {template.lastEditedBy && <span>Last edited by {template.lastEditedBy || "nebula"}</span>}
      </h3>
      <div className={styles.editorSection}>
        <div className={styles.editorWrapper}>
        <CodeMirrorComponent template={template} handleEditorChange={handleEditorChange} setIsEdited={setIsEdited}/>
        </div>
        <div className={styles.validationArea}>
          {hasCronExecutions && renderCronExecutions()}
          <div className={styles.validationResults}>
            <h3>Validation results</h3>
            {parsingError && renderParsingError()}
            <div className={styles.messages}>
              {hasErrors && renderErrors()}
              {hasWarnings && renderWarnings()}
              {isValid && renderValidMessage()}
            </div>
            {isEdited && isValid && <Button label="Save" icon="pi pi-save" onClick={saveTemplate} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template
