import ToastContext from "@/ToastContext"
import { WorkflowTemplate } from "@/types"
import api from "@/api"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { useState, useContext, memo, useEffect } from "react"
import { getEnv } from "@/helpers/envHelper"

import styles from "./Toolbar.module.scss"

interface ToolbarProps {
  workflowTemplates: WorkflowTemplate[]
}

function Toolbar() {
  const [selectedWorkflow, setSelectedWorkflow] = useState<string>("dummy-workflow")
  const [isCreatingWorkflow, setCreatingWorkflow] = useState(false)
  const [cleanupAge, setCleanupAge] = useState(3600000)
  const [isCleaningUpWorkflows, setCleaningUpWorkflows] = useState(false)
  const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplate[]>([])

  const { showError } = useContext(ToastContext)

  useEffect(() => {
    const loadTemplates = async () => {
      const templates = await api.protected.getWorkflowTemplates()
      setWorkflowTemplates(templates)
    }
    loadTemplates()
  }, [])

  const createWorkflow = async () => {
    setCreatingWorkflow(true)
    try {
      // We always want to start the newest version, if we really want to run a specific version, we should implement that specifically
      await api.protected.startWorkflow(selectedWorkflow, undefined)
    } catch (e: any) {
      showError({ msg: e.message })
    }
    setCreatingWorkflow(false)
  }

  const cleanupWorkflows = async () => {
    setCleaningUpWorkflows(true)
    await api.protected.cleanupWorkflows(cleanupAge)
    setCleaningUpWorkflows(false)
  }

  const onCleanupAgeChange = (e: any) => {
    const parsedAge = parseInt(e.target.value)

    if (parsedAge > 0) {
      setCleanupAge(parsedAge)
    }
  }

  if (!workflowTemplates) return null

  const workflowSelectItems = workflowTemplates
    .filter((t) => t.isVisible)
    .map((t) => {
      return { label: t.displayName, value: t.name }
    })

  const cleanupAgeSelectItems = [
    { label: "All", value: 1 },
    { label: "1 m", value: 60000 },
    { label: "1 h", value: 3600000 },
    { label: "1 day", value: 86400000 },
  ]

  const env = getEnv()
  const showCleanup = env === "staging" || env === "development"

  return (
    <div className={styles.actions}>
      <div>
        <Dropdown
          options={workflowSelectItems}
          placeholder="Select a Workflow"
          value={selectedWorkflow}
          onChange={(e) => setSelectedWorkflow(e.value)}
          filter
        />
        <Button
          label="Start workflow"
          icon="pi pi-plus"
          onClick={createWorkflow}
          loading={isCreatingWorkflow}
          disabled={isCreatingWorkflow}
        ></Button>
      </div>
      <div>
        {showCleanup && (
          <>
            <Dropdown options={cleanupAgeSelectItems} value={cleanupAge} onChange={(e) => onCleanupAgeChange(e)} />
            <Button
              label="Cleanup workflows"
              icon="pi pi-trash"
              onClick={cleanupWorkflows}
              loading={isCleaningUpWorkflows}
              disabled={isCleaningUpWorkflows}
            ></Button>
          </>
        )}
      </div>
    </div>
  )
}
export default Toolbar
