import api from "@/api"
import { WorkspaceFile } from "@/types"
import { useState } from "react"
import FileBrowser from "./FileBrowser"

interface WorkspaceProps {
  workflowId: string
}

function Workspace({ workflowId }: WorkspaceProps) {
  const [path, setPath] = useState<string>("/")
  const [fileConent, setFileContent] = useState<string>("")

  if (workflowId == null) return <div>Workflow not found</div>

  const onPathChange = (url: string) => {
    const regex = new RegExp(`.*/${workflowId}`)
    const path = url.replace(regex, "")
    setPath(path)
  }

  // TODO merge
  const onOpenFile = async (workflowId: string, file: WorkspaceFile) => {
    const regex = new RegExp(`.*/${workflowId}`)
    const path = file.url.replace(regex, "")
    const content = await api.protected.getFileContent(workflowId, path)
    setFileContent(content)
  }

  return (
    <div>
      <div>
        <FileBrowser workflowId={workflowId} path={path} onPathChange={onPathChange} onOpenFile={onOpenFile} />
      </div>
      {fileConent && (
        <div>
          <pre>{fileConent}</pre>
        </div>
      )}
    </div>
  )
}
export default Workspace
