import type { AppState, AppStateAction } from "./AppStateContext"

const appStateReducer = (appState: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case 'workflowTemplatesLoaded':
      return {
        ...appState,
        workflowTemplates: [...action.templates]
      }
    default:
      break;
  }
  return appState
}

export default appStateReducer
