import { useEffect, useState } from "react"
import workflowStore from "@/api/workflowStore"
import { Outlet } from "react-router-dom"
import websocket from "@/websocket"
import { getAuthToken } from "@/okta/OktaHelper"
import { ProgressSpinner } from "primereact/progressspinner"

const ProtectedApp = () => {
  const [isWorkflowStoreReady, setWorkflowStoreReady] = useState(false)
  const [isWebsocketReady, setWebsocketReady] = useState(false)

  useEffect(() => {
    const loadWorkflowStore = async () => {
      await workflowStore.init()
      setWorkflowStoreReady(true)
    }
    loadWorkflowStore()
  }, [])

  useEffect(() => {
    const token = getAuthToken()
    const initWebsocket = async () => {
      await websocket.open(token!)
      setWebsocketReady(true)
    }
    if (token) initWebsocket()
  }, [])

  if (!isWebsocketReady || !isWorkflowStoreReady) return <ProgressSpinner />

  return <Outlet />
}

export default ProtectedApp
