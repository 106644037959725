import api from "@/api/xhr"
import { WorkflowQuery } from "@/components/workflows/list/WorkflowListPage"
import {
  TemplateFetchResult,
  TemplateValidationResults,
  Workflow,
  WorkflowExecution,
  WorkflowListEntry,
  WorkflowTemplate,
  WorkspaceFile,
} from "@/types"

const startWorkflow = async (name: string, version?: string): Promise<Workflow> => {
  return api.post("/api/protected/workflows", { name, version })
}

const deleteWorkflow = async (workflowId: string): Promise<void> => {
  return api.delete(`/api/protected/workflows/${workflowId}`)
}

const cleanupWorkflows = async (age: number): Promise<void> => {
  return api.post("/api/protected/workflows/cleanup", { age })
}

const loadMonitor = async (): Promise<Workflow[]> => {
  return api.get("/api/protected/workflows/monitor")
}

const getWorkflowLog = async (workflowId: string): Promise<string> => {
  const response = await api.get(`/api/protected/workflows/${workflowId}/log`)
  return response.content
}

const rerunFailedWorkflowStep = async (workflowId: string, stepId: string) => {
  return api.post(`/api/protected/workflows/${workflowId}/rerun/${stepId}`)
}

const resumeStaleWorkflow = async (workflowId: string) => {
  return api.post(`/api/protected/workflows/${workflowId}/resume`)
}

const getWorkflowTemplates = async (): Promise<WorkflowTemplate[]> => {
  const templates: WorkflowTemplate[] = await api.get("/api/protected/workflowtemplates")
  return templates.sort((a, b) => a.displayName.localeCompare(b.displayName))
}

const getWorkflowTemplate = async (templateId: string): Promise<TemplateFetchResult> => {
  return api.get(`/api/protected/workflowtemplates/${templateId}`)
}

const validateWorkflowTemplate = async (template: WorkflowTemplate): Promise<TemplateValidationResults> => {
  return api.post("/api/protected/workflowtemplates/validate", template)
}

const updateWorkflowTemplate = async (
  templateName: string,
  editedVersion: string,
  template: WorkflowTemplate
): Promise<WorkflowTemplate> => {
  return api.put(`/api/protected/workflowtemplates/${templateName}`, { editedVersion, template })
}

const createWorkflowTemplate = async (template: WorkflowTemplate): Promise<WorkflowTemplate> => {
  return api.post("/api/protected/workflowtemplates", template)
}

const listWorkspaceFiles = async (workflowId: string, path: string): Promise<WorkspaceFile[]> => {
  const cleanPath = path.replace(/^\//, "")

  return api.get(`/api/protected/workspaces/${workflowId}/${cleanPath}`)
}

const getFileContent = async (workflowId: string, file: string): Promise<string> => {
  const response = await api.get(`/api/protected/workspaces/open/${workflowId}${file}`)
  return response.content
}

const getDownloadToken = async (): Promise<string> => {
  const response = await api.get("/api/protected/workspaces/download/token")
  return response.token
}

const getWorkflowExecutions = async (): Promise<Record<string, WorkflowExecution>> => {
  return api.get("/api/protected/workflowexecutions")
}

const queryWorkflows = async (
  query: WorkflowQuery
): Promise<{ ids?: string[]; fileSearchMatches?: Record<string, string>[]; error?: string }> => {
  return api.post("/api/protected/workflows/query", { query })
}

const getWorkflowDetails = async (workflowId: string): Promise<Workflow> => {
  return api.get(`/api/protected/workflows/${workflowId}`)
}

const getWorkflowListEntry = async (workflowId: string): Promise<WorkflowListEntry> => {
  return api.get(`/api/protected/workflows/${workflowId}/listentry`)
}

export default {
  loadMonitor,
  startWorkflow,
  deleteWorkflow,
  cleanupWorkflows,
  listWorkspaceFiles,
  getFileContent,
  getWorkflowTemplates,
  getWorkflowTemplate,
  validateWorkflowTemplate,
  getWorkflowLog,
  getWorkflowExecutions,
  updateWorkflowTemplate,
  createWorkflowTemplate,
  getDownloadToken,
  rerunFailedWorkflowStep,
  resumeStaleWorkflow,
  queryWorkflows,
  getWorkflowDetails,
  getWorkflowListEntry,
}
