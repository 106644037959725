import { getOktaEnv } from "@/helpers/envHelper"

const oktaEnv = getOktaEnv()

const oktaConfig = {
  production: {
    issuer: "https://login.tx.group/oauth2/default",
    clientId: "0oaafks0pueo1IKYz0i7",
  },
  staging: {
    issuer: "https://login.tx.group/oauth2/default",
    clientId: "0oa9z115xezgi0oEx0i7",
  },
  localdev: {
    issuer: "https://login.tx.group/oauth2/default",
    clientId: "0oa9w5t77dqUHLAFk0i7",
  },
}

export const getOktaConfig = () => oktaConfig[oktaEnv]
