import React from "react"
import { Toast } from "./types"

const contextProps: Toast = {
  showError: () => {},
  showSuccess: () => {},
  showInfo: () => {},
  showWarning: () => {},
}
const ToastContext = React.createContext(contextProps)

export default ToastContext
