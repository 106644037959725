import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { Security } from "@okta/okta-react"
import { useNavigate } from "react-router-dom"

import { getOktaConfig } from "@/config/oktaConfig"

interface Props {
  children: JSX.Element | JSX.Element[]
}

// Wrapper around the Okta Security Context.
// If Okta is disabled, we just render the children without Security/Okta context
const OktaSecurity = (props: Props) => {
  const navigate = useNavigate()
  const oktaConfig = getOktaConfig()

  // if (!oktaConfig) return <>{props.children}</>

  const securityConfig = {
    clientId: oktaConfig.clientId,
    issuer: oktaConfig.issuer,
    redirectUri: `${window.location.origin}/implicit/callback`,
    postLogoutRedirectUri: `${window.location.origin}/bye`,
    pkce: true,
    tokenManager: {
      secure: true,
      autoRenew: true,
    },
  }
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin)) // { replace: true }
  }
  const oktaAuth = new OktaAuth(securityConfig)

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </Security>
  )
}

export default OktaSecurity
